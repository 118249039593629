$nav-border-color: #7b7f93;

.kc-topbar {
    @extend section;
    background-color: $color-primary;
    color: white;
    margin: 0;
    position: relative;    
    padding-bottom: 1em;

    @media ($query-gt-medium) {
        font-size: 24px;
    }

    hr {
        width: 80%;
        max-width: 800px;
        border-color: $color-border;
    }

    p {
        max-width: 500px;
        text-align: center;
    }
}
.kc-nav {
    margin-bottom: 4em;
    border-top: 1px solid $nav-border-color;
    border-bottom: 6px solid #fff;
    background-color: $color-primary;

    ul {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        max-width: 61rem;
        margin: 0 auto;
        padding: 0;
    }
    li {
        display: flex;
        align-items: center;; 
        
        flex: 1;
        padding: 1em 0;
        border-right: 1px solid #7b7f93;
        vertical-align: middle;
               

        &:last-child {
            border-right: none;
        }
    }
    a {
        display: inline-block;
        width: 100%;
        text-align: center;
        
        color: #ffffff;
        text-decoration: none;

        font-weight: 600;

        &:hover, &:focus {
            color: $color-anchor--focus;
        }
    }
}
.kc-logo-container {
    
    text-align: center;

    .kc-logo {
        height: 10em;
    }

    h1 {
        letter-spacing: 3px;
    }
    
}

.kc-curve-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    
    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 6px;
        width: 100%;
        background-color: white;
    }
}