.kc-footer {
    font-size: 0.7em;
    background-color: $color-primary;
    color: white;
    border-top: 5px solid white;
    margin-bottom: 0;
    text-align: center;
    
    p {
        margin: 0.6em auto;
    }

    a {
        color: white;
    }

    hr {
        max-width: 90px;
        border-color: #7b7f93;
    }
}