/* All global color/resource variables should be set here */

$color-primary : #1E1F24;
$color-secondary  : #CAE4E8;


$color-border: #5C606D;

$color-background: $color-secondary;

$color-anchor: #1b6c79;

$color-anchor--focus: #ed6f39;



/********* TEXT COLORS *********/


$color-text: $color-primary;


/*** Required for flipping text, pulled from Animate.css ***/
.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  .animated.infinite {
    animation-iteration-count: infinite;
  }
  
  .animated.hinge {
    animation-duration: 2s;
  }
  
  .animated.flipOutX,
  .animated.flipOutY,
  .animated.bounceIn,
  .animated.bounceOut {
    animation-duration: .75s;
 }
 @keyframes flipInX {
    from {
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      animation-timing-function: ease-in;
      opacity: 0;
    }
  
    40% {
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      animation-timing-function: ease-in;
    }
  
    60% {
      transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1;
    }
  
    80% {
      transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
  
    to {
      transform: perspective(400px);
    }
  }
  
  .flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX;
  }