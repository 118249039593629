.kc-profiles {
    width: 90%;
    @media ($query-gt-medium) {
        display: grid;
        grid-template-columns: [left-column] 1fr [right-column] 1fr;
        grid-template-rows: [head] auto [description] auto [skill-list] auto;
        grid-column-gap: 2em;
    }
}

.kc-profile {
    h2 {
        text-align: center;
    }
}
.kc-profile-content {
    grid-row-start: description;
}
.kc-profile-skills {
    grid-row-start: skill-list;
}