@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500i,600,800|Source+Code+Pro');


$font-mono: 'Source Code Pro', monospace;
$font-normal: 'Montserrat', sans-serif;

body {
    font-family: $font-normal;   
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6, em, strong {
    font-weight: 600;
}

figcaption {
    font-style: italic;
    font-weight: 500;
}