.kc-avatar {
    height: 120px;
    width: 120px;
    margin: 0 auto;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.kc-avatar--ryan {
    background-image: url('/images/Ryan_Website.jpg')
}

.kc-avatar--sean {
    background-image: url('/images/Sean_Website.jpg')
}
