/* All elemental, non-component styling should be present here */
html, body {
	width: 100%;
	min-height: 100%;
}

html {
	font-size: 16px;
	color: $color-text;
}

body {
	background: $color-background;
	line-height: 1.4;
}

p {
	font-size: 1.2em;
	line-height: 1.6;
	margin: 1em auto;
}

h1 .large {
	font-size: 1.2em;
}

section, footer {
	margin: 1em auto;
	padding: 1em 5%;
}

a {
	color: $color-anchor;

	&:hover, &:focus {
		color: $color-anchor--focus;
	}
}

img {
	width: 100%;
}
